import {Controller} from 'stimulus'
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default class extends Controller {
    static targets = []

    show(e) {
        e.preventDefault()
        Swal.fire({
            icon: 'success',
            title: `<h2 class="mb-3">Rechung bezahlt am</h2>`,
            html: this.data.get('html'),
            showCancelButton: true,
            confirmButtonText: 'Bezahlt',
            cancelButtonText: 'Abbrechen',
            customClass: {
                confirmButton: 'btn btn-outline-success ms-5',
                cancelButton: 'btn btn-outline-danger me-5'
            },
            buttonsStyling: false,
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.getPopup().querySelector('form').submit()
            }
        })
    }
}