import {config, library, dom} from '@fortawesome/fontawesome-svg-core'
import {faTrash, faEdit, faPlus, faUserClock, faSignOutAlt, faGlobe, faFileExcel, faUserSecret, faGripLines, faCalendarTimes, faExclamationTriangle, faEuroSign} from '@fortawesome/free-solid-svg-icons'

config.mutateApproach = 'sync';

library.add(
    faTrash,
    faEdit,
    faPlus,
    faUserClock,
    faSignOutAlt,
    faGlobe,
    faFileExcel,
    faUserSecret,
    faGripLines,
    faCalendarTimes,
    faExclamationTriangle,
    faEuroSign,
);

dom.watch();