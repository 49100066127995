import {Controller} from 'stimulus'
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {
    static values = {
        resourceName: String,
        handle: String
    }

    initialize() {
        this.onEnd = this.onEnd.bind(this)
    }

    connect() {
        this.sortable = Sortable.create(this.element, {
            animation: 150,
            handle: this.handleValue || undefined,
            onEnd: this.onEnd
        })
    }

    onEnd({item, newIndex}) {
        const param = `${this.resourceNameValue}[order_position]`

        const data = new FormData()
        data.append(param, newIndex)

        Rails.ajax({
            url: item.dataset.sortableUpdateUrl,
            type: 'PATCH',
            dataType: 'script',
            data
        })
    }
}