import {Controller} from 'stimulus'
import Rails from "@rails/ujs";

export default class extends Controller {
    change(e) {
        const data = new FormData()
        data.append("display_date", e.target.value)

        Rails.ajax({
            url: '/display_date',
            type: 'PATCH',
            dataType: 'script',
            data
        })
    }
}